import { useSelector } from 'react-redux';
import {  Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {  TermDates,  CartList } from '../components';
const ShoppingCart = () => {
    const { invoice, modules, siteOrdered } = useSelector((state) => state.order);
    const sitedIdOrdered = siteOrdered[0].template_service_id;
    const formImageStyle = {
        height: '8rem'        
    };
    const ModifyCart = () => {
        const myLink = invoice.is_site_form ? 'sites' : 'services';
        return (
            <Link className="p-2" to={`/${invoice.url_key}/${myLink}/${sitedIdOrdered}`}>
                <button 
                    type="button"                       
                    className="btn bt-sm d-flex align-items-center d-flex-grow-1 justify-content-between btn-secondary">
                    <div className='pr-2'><i className="fa fa-lg fa-chevron-left" aria-hidden="true"></i></div>
                        Modify Order
                </button>
            </Link>
        )
    }

    return (
        <div className="d-flex flex-column">
            
            <div className="d-flex justify-content-between align-items-center flex-wrap pt-4">

                <img
                        style={formImageStyle} 
                        src={invoice.logo_src} alt={`logo for order form`}
                    />
                <div className="d-flex-grow-1"></div>
                <ModifyCart />
            </div>   

            <div>
                <hr />
            </div>   

            {/* <ShoppingCartBar customLink='cart' customText='Cart'/> */}
            <div className="mt-3"></div>
            <TermDates />
            <div className="mt-3"></div>
            <Row>
                <Col sm={12}>
                    <CartList invoice={invoice} modules={modules} siteOrdered={siteOrdered}/> 
                </Col>
            </Row>
            <div className="d-flex flex-row-reverse flex-wrap pt-4">                                            

                <Link  className="p-2" to={`/${invoice.url_key}/checkout`}>
                <button 
                    type="button"   
                    className="btn bt-sm  d-flex align-items-center d-flex-grow-1 justify-content-between btn-primary">
                        Proceed to checkout
                    <div className='pl-2'><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                </button>
                </Link>
               
            </div> 
        </div>
    );
}

export { ShoppingCart };