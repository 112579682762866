import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { formatCurrency } from "../../Utils/formatCurrency";
import {
    addOptional,
    removeAddOn
} from '../../store/actions';


const OptionalService = ({ order }) => {

    const module_id = order.template_service_id;

    const modules = useSelector((state) => state.order.modules);
    const addOns = modules.filter(x => x.parent_template_service_id === +module_id);
    const parentModule = modules.find(x => x.template_service_id === +module_id);

    const dispatch = useDispatch();

    const addExtension = (service, siteId) => {
        if (siteId > 0) {
            dispatch(addOptional({ ...service, quantity: 1, siteId }));
        }
    }

    const removeExtension = (addOn_id, siteId) => {
         dispatch(removeAddOn({ addOn_id, siteId }));
    }

    const isAddOnOrdered = (addOn_id) => {
        if (addOn_id === -1) return false;

        if (order && order.addOns) {
            return !!order.addOns.find(addOn => addOn.template_service_id === addOn_id);
        }

        return false;
    }

    const [showDialog, setShowDialog] = useState(false);
    // setShowDialog(show);
    return (
        <div style={{ width: "100%" }}>
            <span className="addOnClose"
                variant="primary"
                onClick={() => setShowDialog(showDialog === true ? false : true)}
            >
                {order.appType !== 'Individual' &&
                    <div>
                        <i className="fa fa-plus" aria-hidden="true"></i> 
                        <span className="addOptionalTitle">&nbsp;Add Optional Services</span>
                          
                    </div>
                }
            </span>

            {(parentModule && parentModule.service_id !== 2 && showDialog && order.appType !== 'Individual') ?
                <div className="card p-0 ml-0" style={{ "paddingLeft": "-20px", width: "100%" }}  >
                    <div className="card-body pl-0" >
                        {addOns && addOns.length > 0 ? (

                            <ul className="list-group list-group-flush" style={{ "marginTop": "-1em" }}>
                                {addOns.map((service, key) => (
                                    <li key={service.template_service_id} className="list-group-item" style={{ height: "3em" }}>
                                        <div className="d-flex d-flex-row justify-content-between font-weight-light addOnList" >
                                            <div className="form-check" style={{ marginLeft: "-25px" }}>
                                                {isAddOnOrdered(service.template_service_id) ? (
                                                    <i className="fa fa-check-square-o pr-2" onClick={() => removeExtension(service.template_service_id, order.siteId)} aria-hidden="true"></i>
                                                ) : (
                                                    <i className="fa fa-square-o pr-2" onClick={() => addExtension(service, order.siteId)} aria-hidden="true"></i>
                                                )}
                                            </div>
                                            <div data-tooltip-id={service.template_service_id} data-tooltip-content={service.description}>
                                                {isAddOnOrdered(service.template_service_id) ? (
                                                    <small style={{ cursor: 'pointer' }} onClick={() => removeExtension(service.template_service_id, order.siteId)}>{service.service_name}</small>
                                                ) : (
                                                    <small style={{ cursor: 'pointer' }} onClick={() => addExtension(service, order.siteId)}>{service.service_name}</small>

                                                )}

                                            </div>
                                            <div className="flex-grow-1"></div>
                                            <div style={{ "marginRight": "-25px" }}>
                                                <small>
                                                    {formatCurrency(service.price | 0)}
                                                </small>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                        ) : parentModule.service_name}
                    </div>
                    <div className="text-right addOnClose mb-2 mr-2" onClick={() => setShowDialog(false)}><i className="fa fa-window-close" aria-hidden="true"></i> Close</div>
                </div>
                : null
            }
        </div>
    );
}
export { OptionalService }