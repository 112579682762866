/**
 * this component for private school, colleges and institutions 
 * 
 * enrollment or user amounts are required to fill-in
 * 
*/
import { Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { formatCurrency } from "../../Utils/formatCurrency";
import {
    updateSiteOrdered,
} from '../../store/actions';

/**dynamic search */
import axios from 'axios'

import { Tooltip } from 'react-tooltip'

const CancelToken = axios.CancelToken;


const AddSchoolOrganization = ({ moduleBank }) => {

    const { moduleId } = useParams();
    const dispatch = useDispatch();
    const orderModules = useSelector((state) => state.order.modules);
    // const services = orderModules.filter(x => x.parent_template_service_id === +moduleId);
    const addOns = orderModules.filter(x => x.parent_template_service_id === +moduleId);

    const parentModule = orderModules.find(x => x.template_service_id === +moduleId);
    // const invoice = useSelector((state) => state.order.invoice);
    // const siteOrdered = useSelector((state) => state.order.siteOrdered);
    const [siteId, setSiteId] = useState(0);

    //ordering status
    const [ordering, setOrdering] = useState(false);

    /**add school and enrollment number */
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});

  
    //update input element values onChange
    const updateFormElementValue = (elem, value) => {
        setFormData({ ...formData, [elem]: value });
        setSelected(false);

    }

    /**calculate variable prices for Junior, high and Colleges */
    const calculateVariableTotal = (module, enrollment) => {
        //check variable price flag
        if (module && (module.appType==='High' ||
            module.appType === 'Junior')) {
            //this is high or junior 
            return module.price_per_person * enrollment;
        }

        return module.price;
    }

    const assignUnitPrice = (module, enrollment) => {
        //check variable price flag
        if (module && (module.appType === 'High' ||
            module.appType === 'Junior')) {
            //this is high or junior 
            return module.price_per_person;
        }
        else {
            return 0;
        }
    }

    const placeHoldertext = (module, isName)=>{
        if (module.appType === 'High' || 
            module.appType === 'Junior'||
            module.appType === 'College'){
                if (isName){
                    if (module.appType === 'College'){
                        return 'college name';
                    }
                    return 'school name';
                }
                else{
                    if (module.appType === 'College') {
                        return 'optional';
                    }
                    return 'total enrollment';
                }
        }
        else {
            if (isName){
                if (module.appType==='Individual'){
                    return 'Full name'
                }
                return 'organization name';
            }
            else{
                if (module.appType === 'Individual'){
                    return '1';
                }
                return 'optional'
            }
            
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const schoolName = formData.schoolName;
        const enrollment = formData.enrollment;

        const validationErrors = {};
        if (!schoolName) {
            validationErrors.schoolName = 'Required.'
        }

        if (!enrollment && (parentModule.appType ==='High' ||
            parentModule.appType === 'Junior')
        ) {
            validationErrors.enrollment = 'Required.'
        }

        else if (enrollment && !isNumeric(enrollment) && (parentModule.appType === 'High' ||
            parentModule.appType === 'Junior' )) {
            validationErrors.enrollment = 'Must be a whole number.'
        }
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            const _siteId = Date.now();
            
            setSiteId(+_siteId); //siteId is local unique, and 
            // setOrdering(false);
            dispatch(updateSiteOrdered({
                schoolName,
                appType: parentModule.appType,
                quantity: 1,
                service_name: parentModule.service_name,
                price: calculateVariableTotal(parentModule, enrollment),
                template_service_id: +moduleId,
                enrollment: +enrollment,
                siteId: _siteId,
                price_per_person: assignUnitPrice(parentModule, enrollment),
                is_variable_price: Boolean(parentModule.price_per_person),
                addOns: []
            }));
        }
    }

    useEffect(() => {
        setSiteId(-1);
        setOrdering(true);
    }, [moduleId]);

    //check if enrollment field is a number
    const isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    /**real time search */
    const [searchResults, setSearchResults] = useState([]);
    const [selected, setSelected] = useState(false);

    const [focusOnSchoolName, setFocusOnSchoolName]=useState(false);
    const cancelTokenSource = useRef(null);
    useEffect(() => {
        const fetchData = async () => {

            try {
                let SCHOOLLEVEL = '';
                if (parentModule.appType === 'High') {
                    SCHOOLLEVEL = 'High';
                }
                else if (parentModule.appType === 'Junior') {
                    SCHOOLLEVEL = 'Middle';
                }

                const searching = { SEARCHSTR: formData.schoolName, SCHOOLLEVEL }

                cancelTokenSource.current = CancelToken.source();

                await axios.post(
                    `https://schools-api.intocareers.net/api/search`,
                    searching,
                    {
                        cancelToken: cancelTokenSource.current.token,
                    })
                    .then(response => {
                        if (response.data.schools.length===0){
                            setSearchResults(response.data.schools);
                        }
                        else{
                            response.data.schools.unshift({ schoolName: formData.schoolName });
                            setSearchResults(response.data.schools);
                        }
                    });

            } catch (error) {
                return "";
            }

        };

        if (formData.schoolName && formData.schoolName.length > 3 &&
            (parentModule.appType === 'High' ||
                parentModule.appType === 'Junior') &&
                focusOnSchoolName
                ) {

            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel();
            }
            fetchData();
            setErrors([]);
        }
        else {
            setSearchResults([]);
        }
    }, [JSON.stringify(formData.schoolName)]);

    const updateFormValue = (result) => {
     
        let schoolName = '';
        if (!result.city && !result.stateAbbr && !result.zip && !result.Enrollment){
            schoolName = result.schoolName;
        }
        else{
            schoolName = result.schoolName.concat(', ', result.city).concat(' ', result.stateAbbr).concat(' ', result.zip);
        }
        setFormData({
            ...formData,
            schoolName: schoolName, 
            enrollment: result.Enrollment
        });
        setSelected(true);
        setSearchResults([]);
    };

    return (
        
        <div>
            {parentModule.appType!=='Elementary' && 
            <div>
            <div className="p-1 fs-4 fw-bold text-center"> {parentModule.service_name}
                        {(parentModule.appType === 'High' || parentModule.appType === 'Junior') && <div className="priceNote"> {formatCurrency(parentModule.price_per_person)}/student</div>}
                        {(parentModule.appType === 'College') &&
                            <div className='priceNote'>
                                <div>
                                     {formatCurrency(parentModule.price)}/site
                                </div>
                                <div>
                                    10,000+ users: contact us for a quote
                                </div>
                            </div>
                        }
                        {(parentModule.appType === 'Individual') && <div className='priceNote'> {formatCurrency(parentModule.price)}/each</div>}
                        {parentModule.appType === null && <div className='priceNote'> {formatCurrency(parentModule.price)}/site</div>}
            </div>
            <Form>
                <div className="row mt-2">
                    <div className="col">
                        <Form.Group controlId='schoolName'>
                            {(parentModule.appType === 'High' ||
                                parentModule.appType === 'Junior'
                            ) && ( 
                                <Form.Label>School Name<span className='enrollRequired'>*</span></Form.Label>
                            )}
                                    {(
                                        parentModule.appType === 'College'
                                    ) && (
                                            <Form.Label>College Name<span className='enrollRequired'>*</span></Form.Label>
                                        )}
                            {(parentModule.appType === 'Individual' 
                            ) && (
                                    <Form.Label>Full Name<span className='enrollRequired'>*</span></Form.Label>
                                )}
                            {(parentModule.appType === null
                            ) && (
                                    <Form.Label>Organization Name<span className='enrollRequired'>*</span></Form.Label>
                                )}
                            <Form.Control
                                type="text"
                                name="school_name"
                                required
                                placeholder={placeHoldertext(parentModule, true)}
                                value={formData.schoolName || ''}
                                onChange={(e) => updateFormElementValue('schoolName', e.target.value)}
                                isInvalid={errors.schoolName}
                                autoComplete="off"
                                onBlur={() => setFocusOnSchoolName(false)}
                                onFocus={() => setFocusOnSchoolName(true)}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.schoolName}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    {(formData.schoolName === '' || searchResults.length === 0 || selected) ? (
                        null
                    ) : (

                        <div className="search-results">
                            {searchResults && searchResults.map((result, index) => (
                                <div onClick={() => updateFormValue(result)} className="search-result" key={result.ncesNumber ? result.ncesNumber : "-1"}>
                                    {result.schoolName}
                                    {result.city ? (', '.concat(result.city)) : ''}
                                    {!result.stateAbbr ? ' ' : (' '.concat(result.stateAbbr))}
                                    {!result.zip ? ' ' : (' '.concat(result.zip))}
                                    {result.Enrollment ? ('(' + result.Enrollment + ')') : ''}
                                </div>
                            ))}
                        </div>

                    )}
                    <div disabled={!parentModule.is_variable_price} className="col-md-5 col-sm-12">
                        <Form.Group controlId='enrollment'>
                            <Form.Label>
                                {(parentModule.appType === 'High' ||parentModule.appType ==='Junior'
                                    ) && <span>Annual Enrollments </span>
                                            
                                }
                                {(parentModule.appType === null  || parentModule.appType==='Individual' ) &&
                                    <span>Number of Users</span>
                                }
                                {(parentModule.appType === 'College' ) &&
                                            <span>Number of Users </span>
                                }
                            </Form.Label>
                                    {(parentModule.appType === 'High' || parentModule.appType === 'Junior' 
                                    ) && 
                                    <>                                   
                                   &nbsp;<a
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Enrollment is defined as number of students in building with site license."
                                        data-tooltip-place="top"
                                    >
                                        <i className="fa fa-info-circle" aria-hidden="true" title='click' style={{cursor: "pointer", color:"green"}}></i></a>
                                        <Tooltip id="my-tooltip" openOnClick style={{ backgroundColor: "#2266E0", color: "white", width: "250px", cursor: "hand", zIndex:"999"}} />
                                        </> 
                                }
                            <Form.Control
                                type="number"
                                pattern="[1-9]*"
                                name="enrollment"
                                required
                                placeholder={placeHoldertext(parentModule, false)}
                                value={formData.enrollment || ''}
                                onChange={(e) => updateFormElementValue('enrollment', e.target.value)}
                                isInvalid={errors.enrollment}
                                        disabled={parentModule.appType==='Individual'}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.enrollment}
                            </Form.Control.Feedback>
                        </Form.Group>

                    </div>
                </div>
                


                <div className="mb-auto p-2"></div>
                <div className="row">
                    <div className="col">
                        <Button
                            type="submit"
                            className="btn btn-primary btn-block"
                            onClick={onSubmit}
                            disabled={!ordering}
                        > <i className="fa fa-plus" aria-hidden="true"></i>
                             &nbsp;Add to Cart
                        </Button>
                    </div>
                </div>
            </Form>
            
            {/* list all optional service description */}
            {(addOns && addOns.length > 0) && 
            <div className="text-center mt-2">
                <strong>Optional Services for {parentModule.service_name}</strong>
                <hr></hr>
            </div>
            }
            {(addOns && addOns.length>0) && addOns.map((item,index)=>{
                
              return  ( 
              <div key={index}>
              <div>
                   <strong> {item.service_name}</strong>
              </div>
             <div className="ml-4">
                {item.description}
             </div>
                </div>
                )
               
            }
                
            )}
        </div>
}
        </div>
    )
}

export { AddSchoolOrganization };