import { useSelector, useDispatch } from 'react-redux';
import { formatCurrency } from "../../Utils/formatCurrency";
import { removeSiteOrdered } from '../../store/actions';
import '../../../src/styles/orderDetail.css';
import { OptionalService } from "./OptionalService";
import { Link } from "react-router-dom";
const OrderDetail = () => {


    const dispatch = useDispatch();
    const invoice = useSelector((state) => state.order.invoice);
    let sitesOrdered = useSelector((state) => state.order.siteOrdered);

    sitesOrdered = sitesOrdered.sort((a, b) => {
        if (a.siteId < b.siteId) {
            return -1;
        }
        else if (a.siteId > b.siteId){
            return 1 ;
        }
        else 
            return 0;
    });
    const sitesTotal = () => {
        let total = 0;
        if (sitesOrdered && sitesOrdered.length > 0) {
            sitesOrdered.forEach(order => {
                total += siteSubTotal(order);
            })
            return total;
        }
        return total;
    }

    const removeSite = (siteId) => {
        if (siteId && siteId > 0) {
            dispatch(removeSiteOrdered({ siteId }));
        }
    }

    const siteSubTotal = (siteOrder) => {

        const addOnTotal = siteOrder.addOns.reduce((memo, item) => {
            return memo + item.price;
        }, 0);
        return addOnTotal + siteOrder.price;
    }

    const orderPrice = (order) => {
        if (order.appType == null) return <span>{formatCurrency(order.price)}/site</span>;
        switch (order.appType) {
            case "High":
            case "Junior":
            case "College":
                return <span>{formatCurrency(order.price_per_person)}/student</span>;
            case "Individual":
                return <span>{formatCurrency(order.price)}/each</span>;
            default:
                return <span>{formatCurrency(order.price)}/site</span>;
        }
    }

  
    return (
        <>
            <div className='enrollContainer mt-2 mobile-item-in-cart'>
                <div className="enrollTitle mobile-enroll-title">
                    Items in Cart
                </div>
            </div>

            <div className="mt-1 pt-1 pb-1">
                {(sitesOrdered && sitesOrdered.length > 0) &&
                    sitesOrdered.map((order, index) => {
                        return (
                            <div key={index}>
                            <div className='orderContainer ml-2 mt-2'>

                                    <div className='orderTopRow' >
                                    <div className="orderTopRowTitle"><i className="fa fa-building" aria-hidden="true"></i> {order.schoolName}</div>
                                    <div className='orderTopRowAction'>

                                    </div>
                                    <div className='orderTopRowAction'><span onClick={() => removeSite(order.siteId)}><i className="fa fa-trash" aria-hidden="true" ></i></span></div>
                                </div>

                                <div className="orderItemPriceContainer">
                                    <div className="orderItem">{order.service_name}</div>
                                    <div className="orderPrice">{orderPrice(order)}</div>

                                </div>

                                {order.appType ?
                                    <div className="orderItemPriceContainer">
                                        <div className="orderItem">Total Enrollments</div>
                                        <div className="orderPrice">{order.enrollment ? order.enrollment : 'n/a'}</div>
                                    </div>
                                    : null
                                }
                                {order.addOns.length > 0 && order.addOns.map((addOn, index) => {
                                    return (
                                        <div className="orderItemPriceContainer" key={index}>
                                            <div className="orderItem">{addOn.service_name}</div>
                                            <div className="orderPrice">{formatCurrency(addOn.price)}</div>
                                        </div>
                                    )
                                })}

                                <div className="orderItemPriceContainer">
                                    <div className="orderItem">Years:</div>
                                    <div className="orderPrice">1</div>
                                </div>
                                <div className="orderItemPriceContainer subTotal">
                                    <div className="orderItem ">Subtotal:</div>
                                    <div className="orderPrice">{formatCurrency(siteSubTotal(order))}</div>
                                </div>
                                    <div className='orderTopRow'>
                                        <OptionalService order={order} show={true} />
                                    </div>
                            </div>
                            </div>
                        )
                    }
                    )}

                {(sitesOrdered && sitesOrdered.length > 0) &&
                    <div className="orderItemPriceContainer">
                        <div className='orderTotalItem fw-bold'>Total:</div>
                        <div className="orderTotalPrice">{formatCurrency(sitesTotal())}</div>
                    </div>
                }
                {sitesTotal()>0 && (
                <div className="orderDetailContinue">
                    <div className="continueItem"  >
                <Link className="p-2 text-center" to={`/${invoice.url_key}/cart`}>
                    <button
                        type="button"
                        className="btn bt-sm d-flex align-items-left d-flex-grow-1 justify-content-between btn-primary">
                        Proceed to checkout &nbsp;
                         <div className='pr-2'><i className="fa fa-lg fa-chevron-right" aria-hidden="true"></i></div>
                        
                    </button>
                </Link>
                </div>
                </div>
                )}

                {(!sitesOrdered || sitesOrdered.length ===0) &&
                    <span className="ml-1 mt-2">
                        Cart is empty.
                    </span>
                }
            </div>
        </>
    );
}

export { OrderDetail };


